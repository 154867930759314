import { createGlobalStyle } from "styled-components";
import image from "./box-grid.jpg";

const GlobalStyle = createGlobalStyle`
    body{
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        font-family: 'Roboto', sans-serif;
        background: url(${image})  no-repeat fixed;
        background-size: cover;
        color: #fff;
        background-color: #000;
        font-size: 16px;
        animation: zoomIn 3s ease-in-out;

    }

    @keyframes zoomIn {
        from {
            background-size: 100%;
        }
        to {
            background-size: 600%;
        }
    }
    h1{
        font-size: 2.5rem;
        font-weight: 700;
    }
    h2{
        font-size: 2rem;
        font-weight: 700;
    }
    h3{
        font-size: 1.5rem;
        font-weight: 700;
    }

    p{
        font-size: 1rem;
        font-weight: 400;
    }

    @media screen and (min-width: 768px){
        body{
            margin: 0;
            padding: 0;
            overflow-x: hidden;
            font-family: 'Roboto', sans-serif;
            background: url(${image})  no-repeat fixed;
        background-size: cover;
            color: #fff;
            background-color: #000;
            font-size: 16px;
            animation: zoomIn 1s ease-in-out;
    
        }
        @keyframes zoomIn {
            from {
                background-size: 300%;
            }
            to {
                background-size: 800%;
            }
        }
    }
`;

export default GlobalStyle;
