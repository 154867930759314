import React from "react";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import GlobalStyle from "./GlobalStyles";
import "./App.css";
import Eligible from "./components/Eligible";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/check-eligibility" element={<Eligible />} />
        <Route path="*" element={<Navigate to="/check-eligibility" />} />
      </Routes>
    </Router>
  );
}

export default App;

