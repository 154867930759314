import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Container } from "semantic-ui-react";
import "../App.css";
import warning from "../warning.png";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Checker from "../Checker";
import logo from "../logo.png";
import warp from "../warp.png";


function Eligible() {
    const { isConnected } = useWeb3ModalAccount();

  return (
    <div>
        <Container>
        <div className="py-4">
          <header>
            <nav>
              <span className="brand__name">
                <img src={logo} alt="logo" className="img-fluid" />
                <span className="badge rounded-pill bg-warning text-dark">
                  Airdrop
                </span>
              </span>
              <ul style={{ float: "right" }}>
                <w3m-button />
              </ul>
            </nav>
          </header>
        </div>

        <section className="">
          {isConnected ? (
            <>
              <div className="">
                <Checker />
              </div>
            </>
          ) : (
            <>
              <div className="py-5 mt-5">
                <div className="inEligible__box">
                  <div className="text-center">
                    <img src={warning} alt="warning" />
                  </div>
                  <h1 className="text-center m-5">
                    Connect your wallet to check eligibility
                  </h1>
                </div>
              </div>
            </>
          )}
        </section>
      </Container>

      <footer className="py-5">
        <div>
          <h3>
            {" "}
            <img src={logo} alt="logo" className="img-fluid brand__img" />{" "}
            BASENOMICS
          </h3>

          <p className="footer__write">
            Leveraging the power of decentralized finance (DeFi) to provide
            innovative liquidity opportunities for Base community.
          </p>
        </div>

        <h6 className="text-center mt-5">JOIN THE BASENOMICS COMMUNITY</h6>

        <div className="socials">
          <div className="social__item">
            <a
              href="https://twitter.com/base_nomics"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
              <span id="content">twitter</span>
            </a>
          </div>

        
          <div className="social__item">
            <a
              href="https://t.me/base_nomics"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-telegram"></i>
              <span id="content">telegram</span>
            </a>
          </div>

          <div className="social__item">
            <a
              href="https://warpcast.com/~/channel/basenomics"
              target="_blank"
              rel="noreferrer"
            >
              <img src={warp} alt="." className="img-fluid warp"/>
              <span id="content">warpcast</span>
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Eligible
