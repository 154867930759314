import React, { useEffect } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, ethers } from "ethers";
import { Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import axios from "axios";
import "./App.css";
import Timeline from "./Timeline";
import noteligible from "./not-eligible.svg";
import eligible from "./smile.jpeg";
import { Link } from "react-router-dom";

const API_URL = "https://csv-database-ngab.onrender.com/store-address";

function Checker() {
  const [check, setCheck] = React.useState(false);
  const [load, setLoader] = React.useState(false);
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [TotalAllocations, setTotalAllocations] = React.useState(0);
  const scan = process.env.React_APP_ETHERSCAN_API_KEY;

  const events = [
    { date: "Checker", description: `${check ? "✔️" : "❌"}` },
    {
      date: "Eligibility",
      description: `${TotalAllocations >= 1000 ? "✔️" : "❌"}`,
    },
  ];

  // useEffect(() => {
  //   if (check) {
  //     checkActivate();
  //   }
  // }, [address]);

  async function checkActivate() {
    setLoader(true);
    try {
      await getTransactionVolume();
      await getDistinctMonths();
      await getTransactions();
      const info = {
        address: address,
      }
      if(TotalAllocations >= 1000){
        const response = await axios.post(API_URL, info);
        console.log(response);
      }
  
      setLoader(false);
      setCheck(true);
      
    } catch (e) {
      setLoader(false);
      setCheck(false);
      console.log(e);
    }
    setLoader(false);
  }
  
  async function runCheckActivateTwice() {
    await checkActivate();
    setTimeout( async () => {
     await checkActivate();
    }, 1000);
  }
  

  

  async function getTransactionVolume() {
    if (!isConnected) throw Error("User disconnected");

    const item = document.getElementById("hndVol");
    const itemB = document.getElementById("fiveVol");
    const itemC = document.getElementById("maxVol");
    const url = `https://api.basescan.org/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=asc&apikey=${scan}`;

    try {
      const response = await axios.get(url);
      const transactions = response.data.result;
      let totalVolume = 0;

      transactions.forEach((tx) => {
        if (
          tx &&
          tx.from &&
          tx.value &&
          tx.from.toLowerCase() === address.toLowerCase()
        ) {
          totalVolume += parseInt(tx.value);
        }
      });

      const totalVolumeEth = ethers.formatEther(String(totalVolume));
      // console.log(totalVolumeEth);

      const ethToUsdRate = 3700;
      const totalVolumeUsd = parseFloat(totalVolumeEth * ethToUsdRate).toFixed(
        2
      );
      // console.log(totalVolumeUsd);

      if (totalVolumeUsd >= 50000) {
        setTotalAllocations((prev) => prev + 500);
        if (itemB) {
          itemB.style.color = "rgb(58, 185, 58)";
          itemB.innerHTML = "Conducted transactions exceeding $50,000 ✔️";
        }
      } else {
        if (itemB) {
          itemB.style.color = "red";
          itemB.innerHTML = "Conducted transactions exceeding $50,000 ❌";
        }
      }

      if (totalVolumeUsd >= 10000) {
        setTotalAllocations((prev) => prev + 100);
        if (item) {
          item.style.color = "rgb(58, 185, 58)";
          item.innerHTML = "Conducted transactions exceeding $10,000 ✔️";
        }
      } else {
        if (item) {
          item.style.color = "red";
          item.innerHTML = "Conducted transactions exceeding $10,000 ❌";
        }
      }

      if (totalVolumeUsd >= 100000) {
        setTotalAllocations((prev) => prev + 1000);
        if (itemC) {
          itemC.style.color = "rgb(58, 185, 58)";
          itemC.innerHTML = "Conducted transactions exceeding $100,000 ✔️";
        }
      } else {
        if (itemC) {
          itemC.style.color = "red";
          itemC.innerHTML = "Conducted transactions exceeding $100,000 ❌";
        }
      }
    } catch (e) {
      console.log(e);
      if (item) {
        item.style.color = "red";
        item.innerHTML = "Conducted transactions exceeding $10,000 ❌";
      }
      if (itemB) {
        itemB.style.color = "red";
        itemB.innerHTML = "Conducted transactions exceeding $50,000 ❌";
      }
      if (itemC) {
        itemC.style.color = "red";
        itemC.innerHTML = "Conducted transactions exceeding $100,000 ❌";
      }
    }
  }

  async function getDistinctMonths() {
    if (!isConnected) throw Error("User disconnected");

    const item = document.getElementById("twoMnth");
    const itemB = document.getElementById("sixMnth");
    const itemC = document.getElementById("nineMnth");
    const url = `https://api.basescan.org/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=asc&apikey=${scan}`;

    try {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const twoMonthsAgoTimestamp = currentTimestamp - 60 * 24 * 60 * 60;

      const response = await axios.get(url);
      const transactions = response.data.result;

      const filteredTransactions = transactions.filter((tx) => {
        const txTimestamp = parseInt(tx.timeStamp);
        return txTimestamp >= twoMonthsAgoTimestamp;
      });
      const months = new Set();
      filteredTransactions.forEach((tx) => {
        const date = new Date(tx.timeStamp * 1000);
        const monthYear = `${date.getUTCMonth() + 1}-${date.getUTCFullYear()}`;
        months.add(monthYear);
      });

      if (months.size >= 2) {
        setTotalAllocations((prev) => prev + 200);

        if (item) {
          item.style.color = "rgb(58, 185, 58)";
          item.innerHTML = "Conducted 2 distinct month transactions ✔️";
        }
      } else {
        if (item) {
          item.style.color = "red";
          item.innerHTML = "Conducted 2 distinct month transactions ❌";
        }
      }
      if (months.size >= 6) {
        setTotalAllocations((prev) => prev + 600);

        if (itemB) {
          itemB.style.color = "rgb(58, 185, 58)";
          itemB.innerHTML = "Conducted 6 distinct month transactions ✔️";
        }
      } else {
        if (itemB) {
          itemB.style.color = "red";
          itemB.innerHTML = "Conducted 6 distinct month transactions ❌";
        }
      }
      if (months.size >= 9) {
        setTotalAllocations((prev) => prev + 900);

        if (itemC) {
          itemC.style.color = "rgb(58, 185, 58)";
          itemC.innerHTML = "Conducted 9 distinct month transactions ✔️";
        }
      } else {
        if (itemC) {
          itemC.style.color = "red";
          itemC.innerHTML = "Conducted 9 distinct month transactions ❌";
        }
      }
    } catch (e) {
      console.log(e);
      if (item) {
        item.style.color = "red";
        item.innerHTML = "Conducted 2 distinct month transactions ❌";
      }
      if (itemB) {
        itemB.style.color = "red";
        itemB.innerHTML = "Conducted 6 distinct month transactions ❌";
      }
      if (itemC) {
        itemC.style.color = "red";
        itemC.innerHTML = "Conducted 9 distinct month transactions ❌";
      }
    }
  }

  async function getTransactions() {
    if (!isConnected) throw Error("User disconnected");
    const item = document.getElementById("hndTnx");
    const ethersProvider = new BrowserProvider(walletProvider);

    try {
      const total = await ethersProvider.getTransactionCount(address);

      if (item) {
        if (total >= 100) {
          setTotalAllocations((prev) => prev + 100);

          item.style.color = "rgb(58, 185, 58)";
          item.innerHTML = "Conducted more than 100 transactions ✔️";
        } else {
          item.style.color = "red";
          item.innerHTML = "Conducted more than 100 transactions ❌";
        }
      }
    } catch (e) {
      console.log(e);
      if (item) {
        item.style.color = "red";
        item.innerHTML = "Conducted more than 100 transactions ❌";
      }
    }
  }

  return (
    <div className="mt-5">
      <>
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#000",
            border: "1px solid #000",
            borderRadius: "20px",
            padding: "10px 30px",
            fontSize: "16px",
            fontWeight: "bolder",
          }}
          fluid
          onClick={runCheckActivateTwice}
          loading={load}
        >
          Run Checker
        </Button>
        {check ? (
          <>
            <div className="py-2">
              <Timeline events={events} />
            </div>
            <div className="inEligible__box mt-5">
              <div className="text-center">
                {TotalAllocations >= 1000 ? (
                  <>
                    <img
                      src={eligible}
                      alt="."
                      className="img-fluid eligible"
                    />

                    <h1 className="mt-5">You are Eligible.</h1>

                    <p className="mt-5">
                      Keep up with our social media to get the latest updates on
                      how you can join upcoming launches and campaigns in the
                      Basenomics ecosystem. For more information on our token
                      distribution and eligibility.
                    </p>

                    <Link to="https://twitter.com/base_nomics" target="_blank">
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                          border: "1px solid #000",
                          borderRadius: "20px",
                          padding: "10px 30px",
                          fontSize: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        {" "}
                        <i class="fa-brands fa-twitter m-2"></i>
                        Follow @base_nomics
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <img src={noteligible} alt="." />
                    <h1>Sorry, you’re not eligible.</h1>

                    <p className="mt-5">
                      Keep up with our social media to get the latest updates on
                      how you can join upcoming launches and campaigns in the
                      Basenomics ecosystem. For more information on our token
                      distribution and eligibility.
                    </p>

                    <Link to="https://twitter.com/base_nomics" target="_blank">
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                          border: "1px solid #000",
                          borderRadius: "20px",
                          padding: "10px 30px",
                          fontSize: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        {" "}
                        <i class="fa-brands fa-twitter m-2"></i>
                        Follow @base_nomics
                      </Button>
                    </Link>
                  </>
                )}{" "}
              </div>
              <h2>Criteria</h2>
              <ul>
                <li id="twoMnth">Conducted 2 dinstict month transactions</li>
                <li id="sixMnth">Conducted 6 dinstict month transactions</li>
                <li id="nineMnth">Conducted 9 dinstict month transactions</li>
                <li id="hndTnx">Conducted more than 100 transactions</li>
                <li id="hndVol">Conducted transactions exceeding $10,000</li>
                <li id="fiveVol">Conducted transactions exceeding $50,000</li>
                <li id="maxVol">Conducted transactions exceeding $100,000</li>
              </ul>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    </div>
  );
}

export default Checker;
